import React from "react";
import rubi from "../assets/image/rubi.svg";
import googleplay from "../assets/image/googleplay.svg";
import appstore from "../assets/image/appstore.svg";
import { useTranslation } from "react-i18next";
import "../config/i18n";

export default function Home() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <div className="logo-container">
        <img src={rubi} alt="Logo" className="logo" />
      </div>
      <div class="app-links">
        <a href="https://play.google.com/store/apps/details?id=com.rubi.app" class="app-link">
            <img src={googleplay} alt="Google Play" class="store-app"/>
        </a>
        <a href="https://apps.apple.com/app/id6720740387" class="app-link">
            <img src={appstore} alt="App Store" class="store-app"/>
        </a>
      </div>
      <div className="footer-links">
        <a href="https://www.deltyo.com/mentions-legales" target="_blank" rel="noopener noreferrer">
          {t('legal_terms')}
        </a>
        <a href="https://www.deltyo.com/en/privacy" target="_blank" rel="noopener noreferrer">
          {t('privacy_policy')}
        </a>
        <a href="https://www.deltyo.com/en/tos" target="_blank" rel="noopener noreferrer">
          {t('terms_of_service')}
        </a>
        <a href="mailto:contact@deltyo.com">
          {t('contact')}
        </a>
      </div>
    </div>
  );
}
