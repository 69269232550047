import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next
  .use(initReactI18next)
  .init({
    fallbackLng: "fr",
    debug: false, // Mettre à true pour activer le débogage
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          legal_terms: 'Legal terms',
          privacy_policy: 'Privacy policy',
          terms_of_service: 'Terms of service',
          contact: 'Contact',
        },
      },
      fr: {
        translation: {
          legal_terms: 'Mentions légales',
          privacy_policy: 'Politique de confidentialité',
          terms_of_service: `Conditions générales d'utilisation`,
          contact: 'Contact',
        },
      },
    },
  });

export default i18next;
